import React from "react"

// My components
import SEO from "../components/seo"
import Section from "../components/section"
import BackgroundImage from "../components/backgroundImage"

export default () => {
  return (
    <>
      <SEO
        title="donate"
        keywords={[
          `st john's`,
          `methodist`,
          `church`,
          "bloxwich",
          "walsall",
          "wolverhampton",
          "christianity",
          "easter",
        ]}
      />
      <BackgroundImage
        src="https://ucarecdn.com/29744404-315f-4d3f-b956-4f5f67879d92/"
        alt={`donations-banner`}
      ></BackgroundImage>
      <Section heading="Thank You" body="Your donation had been successful" />
    </>
  )
}
